@import "slick-carousel/slick/slick.css";
@import "slick-carousel/slick/slick-theme.css";


.main-content h1 {
  text-align: center;
  font-size: 40px;
  font-weight: 500;
  color: #fff;
  margin: 0;
  padding: 30px 0px;
}

img.av-log {
  max-width: 300px;
}

.main-content {
  /* background-image:  `url("https://via.placeholder.com/500")` ; */
  background: url(assets/images/AM_BGs/bd_bg.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  /* background:#3a677e; */
  height: auto;
    min-height: 100vh;
 
}

.mintButtonOuter {
  max-width: 1300px;
  width: 100%;
  display: flex;
  margin: 0px auto;
  justify-content: flex-end;
  position: relative;
  padding-bottom: 20px;
}

.middle-content-part {
  max-width: 1300px;
  width: 100%;
  display: flex;
  margin: 0px auto;
  padding: 40px 0px;
  justify-content: space-between;
}

.mintButtonOuter .MintButton,
.AvaHead .walletButton {
  background-image: linear-gradient(to bottom right, #db35fa, #835ff2);
  width: 100%;
  max-width: 170px;
  padding: 15px 0px;
  font-size: 1rem;
  font-weight: 600;
  color: #fff;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  display: flex;
  justify-content: center;
}

.mintButtonOuter .MintButton {
  position: relative;
  z-index: 1;
}

.left-text {
  float: left;
  width: 45%;
  padding: 0px;
  /* background-image: linear-gradient(to bottom right, #db35fa, #835ff2); */
  text-align: center;
  position: relative;
  border-radius: 5px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

img.box {
  width: 57px;
  position: absolute;
  bottom: 0px;
  left: 17px;
  z-index: 100;
  cursor: pointer;
}

img.lg-img {
  position: relative;
  width: 100%;
  max-width: 550px;
  /* left: -38px; */
}
.avatar-viewport {
  
}
.boxItem {
  width: 100%;
  max-width: 550px;
  position: absolute;
  left: auto;
  right: auto;
  margin: 0 auto;
}

.avatar-frame {
  border-radius: 5px;
}

.bodyHead {
  z-index: 99;
}

.bodyShoulder {
  z-index: 1;
}

.faceMask {
  z-index: 1;
}

.right-text {
  float: right;
  width: 53%;
 
  border-radius: 5px;
  /* From https://css.glass */
  background: #0f121d94;
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(13, 13, 26, 0.404);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
}

.icn-list {
  display: flex;
  padding: 0;
  margin: 20px 0px 8px;
  width: 100%;
  justify-content: center;
  flex-wrap: wrap;
}

.icn-list li {
  list-style: none;
  background-color: #0000001c;
  padding: 10px;
  border: 2px solid;
  border-radius: 15px;
  border-color: #835ff2;
  cursor: pointer;
  background-image: linear-gradient(
    to bottom right,
    #200b3596,
    #230b699a
  ) !important;
  transition-duration: 0.3s;
}


.icn-list li:hover {
  
  background-image: linear-gradient(
    to bottom right,
    #b15ffd,
    #29088d
  ) !important;
}

.icn-list.active li {
  
  background-image: linear-gradient(
    to bottom right,
    #d85ffd,
    #491cd1
  ) !important;
}

.icn-list li:hover img {
  
}

.img-dis-active {
  background-image: linear-gradient(
    to bottom right,
    #b15ffd,
    #29088d
  ) !important;
}

.icnActive {
  /* background-image: linear-gradient(to bottom right, #db35fa, #835ff2);
  transform: scale(1.1); */
  transform: scale(1.2);
  border: 3px solid #fff;
}

.icn-list li img {
  width: 40px;
  height: auto;
  border-radius: 5px;
}

.display-bar div {
  float: none;
  display: inline-block;
  width: 25%;
  vertical-align: top;
}

.display-bar {
  height: 380px;
  overflow: auto;
  padding-top: 15px;
  position: relative;
}

.select-bar select {
  width: 100%;
  height: 45px;
  padding: 0 9px;
  font-size: 18px;
  font-weight: 500;
  border-radius: 5px;
  background: #000;
  color: #fff;
  border: 0;
  outline: none;
  appearance: none;
  background-repeat: no-repeat;
  background-position: 98%;
  background-size: 16px;
}

.select-bar {
  padding: 15px 20px;
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
  margin: 15px 0px;
}

.img-display-list {
  display: flex;
  padding: 0;
  margin: 0;
  flex-wrap: wrap;
  justify-content: center;
}

ul.img-display-list li:hover {
  background-image: linear-gradient(to bottom right, #db35fa, #835ff2);
}

.img-display-list li {
  float: none;
  display: inline-block;
  text-align: center;
  height: 150px;
  width: 150px;
  line-height: 160px;
  background-image: linear-gradient(to bottom right, #818197, #f4f4f5);
  align-items: center;
  margin: 0 10px 20px 10px;
  border-radius: 5px;
  cursor: pointer;
}

.img-display-list img {
  width: 120px;
  height: 120px;
  vertical-align: bottom;
  display: inline-block;
  position: relative;
  top: -10px;
}

.withlist li {
  background: transparent !important;
}

.AvaHead {
  position: relative;
  width: 100%;
  max-width: 1300px;
  margin: 0 auto;
}
.AvaHead .walletButton {
  position: absolute;
  top: 35%;
  right: 0;
}

.slick-track {
  padding: 0px 0px;
}

#status {
  position: absolute;
  left: auto;
  right: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.mintButtonOuter button.MintButton {
  max-width: 127px;
}

#cs-scrolling::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background: linear-gradient(to bottom right, #818197, #f4f4f5);
  border-radius: 5px;
}

#cs-scrolling::-webkit-scrollbar {
  width: 10px;
  /* background-color: #f5f5f5; */
}

#cs-scrolling::-webkit-scrollbar-thumb {
  background: linear-gradient(to bottom right, #db35fa, #835ff2);
  border-radius: 5px;
  height: 100px;
}


.middleFilterIcon img {
  width: 40px;
}

.middleFilterIcon{
  position: absolute;
  z-index: 9999;
  left: 25px;
  top: 130px;
  /* background: #835ff2; */
  border:red solid 2px;
  border-radius: 16px;
  background-color: black;
  padding: 5px;
  padding-top: 8px;
  list-style: none;
  border: 2px solid;
  border-radius: 15px;
  border-color: #f25fba;
  cursor: pointer;
  transition: box-shadow 0.3s ease-in-out;
}

.middleFilterIcon:hover{
  box-shadow: 0px 0px 10px 10px #f25fba6e;
  transition: box-shadow 0.3s ease-in-out;
}

.middleFilterOption {
  position: absolute;
  top: 0px;
  left: 0;
  width: 100%;
  background-color: #331a4291;
  border-radius: 5px;
  transition: all ease 1s;
}

.middleFilterOption ul {
  list-style: none;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-left: 0;
  margin: 10px auto;
  height: 74px;
}

/* image layers  */

.face {
  z-index: 2;
}

.helmet {
  z-index: 7;
}

.mask {
  z-index: 5;
}

.bottom_accessory {
  z-index: 4;
}

.bottom_clothing {
  z-index: 3;
}

.top_accessory {
  z-index: 11;
}

.top_clothing {
  z-index: 8;
}

.body {
  z-index: 1;
}
.hood_hat_hair {
  z-index: 12;
}
.avatarBackground {
  z-index: unset;
}

/* image layers end  */

.activeColor {
  background-image: linear-gradient(
    to bottom right,
    #7c5ff3,
    #6abfe3
  ) !important;
  padding: 7px 12px !important;
  color: rgb(255 255 255) !important;
  border-radius: 20px !important;
}

.hide {
  display: none;
  top: 0;
}
.middleFilterOption.show {
  max-width: 540px;
  margin: 0px auto;
  display: block;
  left: 0;
  right: 0;
  top: 2px;
  border: 2px solid #835ff2;
}

.no-symb {
  margin-left: auto;
}

.no-symb span{
  border:red solid 2px;
  border-radius: 16px;
  background-color: black;
  padding: 5px;
  padding-top: 8px;
  list-style: none;
  border: 2px solid;
  border-radius: 15px;
  border-color: #f25fba;
  cursor: pointer;
  transition: box-shadow 0.3s ease-in-out;
}

.no-symb span:hover{
  box-shadow: 0px 0px 10px 10px #f25fba6e;
  transition: box-shadow 0.3s ease-in-out;
}


.no-symb img{
  margin-left: auto;
  width: 40px;
  cursor: pointer;
}

.middleFilterOption.show li {
  background: transparent !important;
  display: inline-block;
  width: 14.66%;
  position: relative;
  left: 0px;
}

.middleFilterOption ul li {
  background-image: linear-gradient(to bottom right, #db35fa, #835ff2);
  /* padding: 7px 12px; */
  color: #fff;
  
}

.middleFilterOption.show ul li img {
  width: 75px;
  height: 75px;
  border-radius: 5px;
}

.middle-content-part .slick-prev {
  left: 5px;
  top: 50px;
  z-index: 999;
}

.middleFilterOption.show ul .activeColor {
  border-radius: 0px !important;
}

.middleFilterOption.show ul {
  justify-content: center;
  margin: 0px auto;
  overflow: hidden;
}

.middle-content-part .slick-next {
  right: 5px;
  top: 50px;
}

.sh-pop {
  position: absolute;
  width: 99.5% !important;
  left: 0;
  right: 0;
  height: 100%;
  background: #252835;
  margin: 0px auto;
  top: 0;
  display: none !important;
  border: 2px solid #835ff2;
  border-radius: 5px;
}

.show-popup {
  display: block !important;
}

.upr-part {
  display: flex !important;
  align-items: center;
  margin: 0px auto;
  width: 95% !important;
  max-width: 650px;
}

.upr-part button {
  margin-left: auto !important;
  text-transform: capitalize;
  background-image: linear-gradient(to bottom right, #db35fa, #835ff2);
  color: #fff;
  border: 0;
}

.btm-part {
  margin-top: 12px !important;
  width: auto !important;
  margin: 0px auto;
  display: block !important;
}

.btm-part ul.img-display-list {
  width: 99%;
  margin-bottom: 10px;
  overflow: auto;
  height: 315px;
  margin: 0px auto;
}

.btm-part ul.img-display-list:last-child {
  margin-bottom: 0;
}

.btm-part ul.img-display-list li {
  width: 150px;
  height: 150px;
  margin: 0 6px 8px;
  overflow: hidden;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btm-part ul.img-display-list li.img-dis-active {
  margin-bottom: 11px;
}

.btm-part ul.img-display-list li img {
  height: 90%;
  width: 90%;
}

.btm-part ul.img-display-list.zoom-face li img {
  height: 150%!important;
  width: 150%!important;
  top: 15px!important;
  vertical-align: bottom;
}

.main-head {
  position: relative;
}

.upr-part p {
  color: #fff;
  text-transform: uppercase;
  font-size: 20px;
  font-weight: 600;
}

.select-asset {
  display: flex;
  align-items: center;
  padding: 0 20px;
}

.select-asset h4 {
  color: #fff;
  font-size: 20px;
}

.box-active {
  border: 4px solid #835ff2;
  border-top: 0;
  border-bottom: 0px;
}

.inner-pop {
  position: absolute;
  width: 98.6% !important;
  left: 0;
  right: 0;
  height: 100%;
  background: #252835;
  margin: 0px auto;
  top: 0;
  /* display: none !important; */
  /* border: 5px solid #835ff2; */
}


.light-variation {
  cursor: pointer;
  margin: 0 2px;
}



.slick-slide {position: relative;}

.slick-slide:hover p.tooltip-text {
  transform: translateY(0px);
  opacity: 1;
  transition: 0.5s;
}
p.tooltip-text {
  text-align: center;
  color: #fff;
  transition: 0.5s;
  position: absolute;
  top: -20px;
  left: 0;
  right: 0;
  transform: translateY(30px);
  opacity: 0;
  background: #000;
  padding: 5px;
  border-radius: 5px;
  z-index: 99999;
  font-size: 12px;
  width: 100%;
}


.slick-list {
  padding: 10px 0px;
}


p.tooltip-text:after {
  content: "";
  display: block;
  clip-path: polygon(100% 0, 0 0, 51% 96%);
  height: 12px;
  width: 14px;
  background: #000;
  position: absolute;
  left: 0;
  right: 0;
  margin: 0px auto;
  bottom: -12px;
}

.submenu-bar {
  background: #000;
  text-align: center;
  color: #fff;
  padding: 15px;
  font-size: 19px;
  text-transform: capitalize;
  max-width: 200px;
  left: 0;
  right: 0;
  margin: 0px auto;
  position: absolute;
  border: 1px solid;
  display: none;
}


@media (min-width: 1300px) and (max-width: 1399px) {
  .mintButtonOuter button.MintButton {
    max-width: 130px;
  }
}

@media screen and (max-width: 1300px) {
  .main-content {
    height: auto;
  }
  .middle-content-part {
    flex-direction: column;
    width: 95%;
  }
  .left-text,
  .right-text {
    width: 100%;
  }

  .right-text {
    margin-top: 20px;
  }

  .left-text {
    /* margin-bottom: 20px; */
    height: 550px;
    max-width: 550px;
    margin: 0px auto;
  }

  img.box {
    left: 10px;
  }

  
  
  .middleFilterIcon {
    left: 10px;
    top: 10px;
  }

  .AvaHead {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 95%;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }

  .AvaHead .walletButton {
    position: inherit;
  }
  .main-content h1 {
    font-size: 1.5rem;
    border: none;
  }
  .mintButtonOuter {
    width: 95%;
    justify-content: center;
    padding-bottom: 20px;
  }
}

@media (min-width: 1200px) and (max-width: 1299px) {
}

@media (min-width: 1025px) and (max-width: 1199px) {
}

@media (min-width: 992px) and (max-width: 1024px) {
  /* .boxItem {
    left: 170px;
  } */

  .img-display-list li {
    width: 100%;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
}

@media (max-width: 767px) {
  .middleFilterIcon {
    top: 10px;
  }

  .boxItem {
    max-width: 100%;
    height: 100%;
    width: 100%;
    top: 0;
  }
  .AvaHead {
    border-bottom: 0;
  }

  .middleFilterOption.show {
    top: -69px;
    padding: 0px;
  }

  .mintButtonOuter .MintButton,
  .AvaHead .walletButton {
    max-width: 150px;
    padding: 15px 0px;
    font-size: 14px;
  }

  .btm-part ul.img-display-list li img {
    width: 108px;
  }
  .btm-part ul.img-display-list li {
    width: 17%;
  }
}

@media screen and (max-width: 600px) {
  .btm-part ul.img-display-list li {
    height: 100px;
  }

  .btm-part ul.img-display-list li img {
    width: 100%;
    top: 20px;
    height: 80px;
  }

  img.av-log {
    max-width: 175px;
  }

  .icn-list li img {
    width: 35px;
  }

  .img-display-list img {
    width: 100%;
    height: 115px;
    vertical-align: bottom;
    display: initial;
    top: 5px;
    position: relative;
  }

  /* .boxItem {
    left: -37px;
  } */

  .display-bar div {
    width: 33%;
    vertical-align: middle;
  }

  .img-display-list li {
    height: 120px;
    line-height: normal;
  }

  .middleFilterIcon {
    left: 5px;
    top: 5px;
  }

  img.box {
    width: 30px;
    left: 5px;
    right: inherit;
  }
  .middleFilterOption ul {
    overflow: auto;
  }
  .middleFilterOption ul li {
    margin-right: 5px;
  }
}

@media (max-width: 480px) {
  .btm-part ul.img-display-list {
    width: 98%;
  }

  .btm-part ul.img-display-list li {
    width: 28%;
    height: 100px;
    margin: 0 6px 8px;
  }

  .btm-part ul.img-display-list li img {
    height: 80px;
    width: 100%;
    position: relative;
  }
  .upr-part {
    max-width: 300px;
  }

  .btm-part ul.img-display-list {
    max-width: 308px;
  }

  .upr-part p {
    font-size: 15px;
  }

  .upr-part button {
    font-size: 13px;
  }

  .img-display-list li {
    height: 105px;
    overflow: hidden;
  }

  .img-display-list img {
    height: 95px;
    top: 10px;
  }

  .AvaHead .walletButton {
    max-width: 115px;
    font-size: 13px;
  }

  .no-symb img {
    width: 30px;
  }

  .select-asset h4 {
    color: #fff;
    font-size: 15px;
  }

  .left-text {
    height: 100vw;
    margin-top: 20px;
  }

  .middleFilterOption.show {
    border: 0px;
    background: linear-gradient(to bottom right, #db35fa69, #835ff26b);
  }


  .middleFilterOption.show li {
    width: 12.66%;
    left: -25px;
  }

}
